import authAxios2 from "../shared/axios/authAxios2";

export default class StakeholderBookingService {

  static async getAllStakeholderBooking(orderBy?, limit?, offset?, filter?) {
    const sinDespachante: Boolean = filter?.stakeholder === 'true';
    let filterDespachante = { ...filter, sinDespachante: true, stakeholder: undefined }

    const params = {
      sort: orderBy,
      size: limit,
      page: offset,
      filter: sinDespachante ? filterDespachante : filter,
    };
    const response = await authAxios2.get(`/portal/order`, { params });
    return response.data;
  }

  static async asignacionBooking(values) {
    const response = await authAxios2.post(`/portal/booking?cuit=${values.cuit}&booking=${values.booking}`);
    return response.data;
  }

  static  async getBookingByCuitAndNumberBooking(values){
    const response = await authAxios2.get(`/portal/booking?cuit=${values.cuit}&booking=${values.booking}`);
    return response;
  }

  static async createEmptyBody(data) {
    const response = await authAxios2.post("/portal/emptyBody", data);
    return response.data;
  }

  static async EditEmptyBody(data) {
    const response = await authAxios2.patch(
      "/portal/emptyBody/edit-emptyBody",
      data
    );
    return response.data;
  }

  static async deleteEmptyBody(data) {
    const response = await authAxios2.delete(`/portal/emptyBody/${data}`);
    return response.data;
  }

  static async deleteEmptyHeader(data) {
    const response = await authAxios2.delete(`/portal/emptyHeader/${data}`);
    return response.data;
  }

  static async findEmptyHeaderById(data: any) {
    const response = await authAxios2.get(`/portal/emptyHeader/${data}`);
    return response.data;
  }

  static async findEmptyBodyByEmptyHeaderId(data: any) {
    const response = await authAxios2.get(`/portal/emptyBody/${data}`);
    return response.data;
  }

  static async createReservationEmptyBody(data) {
    const response = await authAxios2.post(
      `/portal/reservation/${data.turnId}/reservationByEmptyBody?date=${data.date}&emptyBodyId=${data.idBody}`
    );

    return response.data;
  }

  static async getclientCompanies(orderBy, limit, offset, filter) {
    const params = {
      sort: orderBy,
      size: limit,
      page: offset,
      filter: filter,
    };
    const response = await authAxios2.get(`/portal/clientCompany/`, {
      params,
    });

    return response.data;
  }
  static async getOrders(orderBy, limit, offset, filter) {
    const params = {
      sort: orderBy,
      size: limit,
      page: offset,
      filter: filter,
    };


    const response = await authAxios2.get(`/portal/order`, {
      params,
    });
    return response.data;
  }
  static async searchBooking(data) {
    const { bookingNumber, clientCompanyCUIT } = data;

    const response = await authAxios2.get(
      `/portal/navis/booking?booking=${bookingNumber}&cuit=${clientCompanyCUIT}`
    );

    return response.data.data;
  }

  static async searchCuit(data) {
    const response = await authAxios2.get(
      `/portal/softland/softlandByClienteFacturable?cuit=${data}`

    );

    return response.data.data;
  }

  static async scheduledOther(data) {

    const response = await authAxios2.post(
      `/portal/reservation/reservationOutOfRangeByEmptyBody?date=${data.date}&emptyBodyId=${data.id}`
    );
    return response.data;
  }
}
