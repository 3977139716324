const Cookies = require("js-cookie");

const COOKIE_NAME = "cookiesAccepted";

const cookieService = {
  setCookie: (name: string, value: string, days: number = 365) => {
    Cookies.set(name, value, { expires: days, path: "/" });
  },

  getCookie: (name: string): string | undefined => {
    return Cookies.get(name);
  },

  removeCookie: (name: string) => {
    Cookies.remove(name);
  },

  hasAcceptedCookies: (): boolean => {
    return Cookies.get(COOKIE_NAME) === "true";
  },

  acceptCookies: () => {
    Cookies.set(COOKIE_NAME, "true", { expires: 365, path: "/" });
  },
};

export default cookieService;
