import { createSelector } from "reselect";

const selectRaw = (state) => state.consolidated.list;

const selectLoading = createSelector([selectRaw], (raw) => raw.loading);
const selectLoadingTurnConsolidated = createSelector(
  [selectRaw],
  (raw) => raw.loadingTurnConsolidated
);
const selectLoadingTurnId = createSelector(
  [selectRaw],
  (raw) => raw.loadingTurnId
);

const selectInitLoading = createSelector([selectRaw], (raw) =>
  Boolean(raw.initLoading)
);
const selectLoadingUsers = createSelector(
  [selectRaw],
  (raw) => raw.loadingUsers
);

const selectInitLoadingUsers = createSelector([selectRaw], (raw) =>
  Boolean(raw.initLoadingUsers)
);

const selectRows = createSelector([selectRaw], (raw) => raw.rows);

const	getTurnos	=	(arra)	=>	{
	return	arra.map(({mercaderia,	clientCompany,  embalaje, buque,  contenedoresDeclarados, mercaderiasPorContenedor, camionesDeclarados, fechaTentativa, booking,  status})	=>	({
		companyName: clientCompany	?	clientCompany.companyName	:	"no-data",
		navisId:	mercaderia	?	mercaderia.navisId	:	"no-data",
		embalaje:	embalaje	?	embalaje.navisId	:	"no-data",
		buque:	buque	?	buque	:	"no-data",
		contenedoresDeclarados:	contenedoresDeclarados	?	contenedoresDeclarados	:	"no-data",
    mercaderiasPorContenedor:	mercaderiasPorContenedor	?	mercaderiasPorContenedor	:	"no-data",
    camionesDeclarados:	camionesDeclarados	?	camionesDeclarados	:	"no-data",
    fechaTentativa,
    booking:	booking	?	booking	:	"no-data",
    status,
	}))
}

const selectRowsCsv = createSelector([selectRaw], ({rows}) => {

  const columnsHeader =  [
		{id:"companyName", displayName:  "Exportador"},
		{id:"navisId", displayName:  "Mercaderia"},
		{id:"embalaje", displayName:  "Embalaje"},
		{id:"buque", displayName:  "Buque"},
		{id:"contenedoresDeclarados", displayName:  "Contenedores"},
		{id:"mercaderiasPorContenedor", displayName:  "Mercaderia por Contenedor"},
		{id:"camionesDeclarados", displayName:  "Camiones"},
		{id:"horarioTentativo", displayName:  "Turno Tentativo"},
		{id:"booking", displayName:  "Booking"},
		{id:"status", displayName:  "Estado"},
	  ]
	  
  const data  = getTurnos(rows);

  return	[data,  columnsHeader];

});

const selectRowsOperacionesTipo = createSelector(
  [selectRaw],
  (raw) => raw.rowsOperacionesTipo
);
const selectLoadingOperacionesTipo = createSelector(
  [selectRaw],
  (raw) => raw.loadingOperacionesTipo
);

const selectRowsTurnConsolidated = createSelector(
  [selectRaw],
  (raw) => raw.rowsTurnConsolidated
);

const	getOperacionesConsolidado	=	(arra)	=>	{
	return	arra.map(({turnDate,	turnFrom,  turnTo, clientCompanyName, buque, mercaderia, booking,  status})	=>	({
		turnDate,
		franjaHoraria:	`${turnFrom} - ${turnTo}`,
		clientCompanyName,
		booking,
		buque,
    mercaderia,
    status,
	}))
}

const selectRowsTurnConsolidatedCsv = createSelector(
  [selectRaw],
  ({rowsTurnConsolidated}) => {
    const columnsHeader =  [
      {id:"turnDate", displayName:  "Fecha"},
      {id:"franjaHoraria", displayName:  "Franja Horaria"},
      {id:"clientCompanyName", displayName:  "Exportador"},
      {id:"booking", displayName:  "Booking"},
      {id:"buque", displayName:  "Buque"},
      {id:"mercaderia", displayName:  "Mercadería"},
      {id:"status", displayName:  "Estado Turno"},
     ]
      
    const data  = getOperacionesConsolidado(rowsTurnConsolidated);
  
    return	[data,  columnsHeader];


  });
const selectCountTurnConsolidated = createSelector(
  [selectRaw],
  (raw) => raw.countTurnConsolidated
);
const selectHasRowsTurn = createSelector(
  [selectRaw],
  (raw) => raw.countTurnConsolidated > 0
);

const selectRowsUserConsolidated = createSelector(
  [selectRaw],
  (raw) => raw.rowsUserConsolidated
);
const selectCountUserConsolidated = createSelector(
  [selectRaw],
  (raw) => raw.countUserConsolidated
);

const selectConsolidatedById = createSelector(
  [selectRaw],
  (raw) => raw.rowById
);

const validationHeader = createSelector(
  [selectRaw],
  (raw) => raw.validationHeaderRowById
);

const selectTrucks = createSelector([selectRaw], (raw) => raw.trucks);

const selectTruckById = createSelector([selectRaw], (raw) => raw.truckById);

const selectPackaging = createSelector([selectRaw], (raw) => raw.packaging);

const selectTurns = createSelector([selectRaw], (raw) => raw.turns);

const selectTime = createSelector([selectRaw], (raw) => raw.timeFring);

const selectorOrderTime = createSelector([selectTime], (time) => {
  return time.map((time) => ({
    value: time,
    label: time,
  }));
});
const selectorOrderPackaging = createSelector(
  [selectPackaging],
  (packaging) => {
    return packaging.map((packaging) => ({
      value: packaging.id,
      label: packaging.navisId,
    }));
  }
);

const selectCommodity = createSelector([selectRaw], (raw) => {
  return raw.commodity;
});

const selectorCommodityOptions = createSelector(
  [selectCommodity],
  (commodity) => {
    return commodity.map((commodity) => ({
      value: commodity.id,
      label: commodity.navisId,
    }));
  }
);

const selectPreStatus = createSelector([selectRaw], (raw) => raw.preStatus);

const selectStatus = createSelector([selectRaw], (raw) => raw.status);

const selectTransport = createSelector([selectRaw], (raw) => raw.transport);

const selectorTransportOptions = createSelector(
  [selectTransport],
  (transport) =>
    transport.map((t: any) => ({
      value: t,
      label: t,
    }))
);

const selectRemitos = createSelector([selectRaw], (raw) => raw.remitos);
const selectHasRowsRemito = createSelector(
  [selectRaw],
  (raw) => raw.countRemitos > 0
);

const selectTurnId = createSelector([selectRaw], (raw) => raw.turnsById);

const selectFiles = createSelector([selectRaw], (raw) => raw.files);

const selectServices = createSelector([selectRaw], (raw) => raw.services);

const selectStatusConsolidated = createSelector(
  [selectRaw],
  (raw) => raw.statusConsolidated
);

const selectCount = createSelector([selectRaw], (raw) => raw.count);

const selectHasRows = createSelector([selectRaw], (raw) => raw.count > 0);

const selectSorter = createSelector([selectRaw], (raw) => raw.sorter || {});

const selectOrderBy = createSelector([selectRaw], (raw) => {
  const sorter = raw.sorter;

  if (!sorter) {
    return null;
  }

  if (!sorter.field) {
    return null;
  }

  let direction = sorter.order === "desc" ? "DESC" : "ASC";

  return `${sorter.field},${direction}`;
});

const selectFilter = createSelector([selectRaw], (raw) => {
  return raw.filter;
});
const selectFilterOperation = createSelector([selectRaw], (raw) => {
  return raw.filterOperation;
});

const selectRawFilter = createSelector([selectRaw], (raw) => {
  return raw.rawFilter;
});

const selectLimit = createSelector([selectRaw], (raw) => {
  const pagination = raw.pagination;
  return pagination.pageSize;
});

const selectOffset = createSelector([selectRaw], (raw) => {
  const pagination = raw.pagination;

  if (!pagination || !pagination.pageSize) {
    return 0;
  }

  const current = pagination.current || 1;

  return current - 1;
});

const selectPagination = createSelector(
  [selectRaw, selectCount],
  (raw, count) => {
    return {
      ...raw.pagination,
      total: count,
    };
  }
);

const selectCurrentOrderBy = createSelector([selectRaw], (raw) => {
  const sorter = raw.sorterOrder;

  if (!sorter) {
    return null;
  }

  if (!sorter.field) {
    return null;
  }

  let direction = sorter.order === "desc" ? "DESC" : "ASC";

  return `${sorter.field},${direction}`;
});

const selectFilterOrder = createSelector([selectRaw], (raw) => {
  return raw.orderFilter;
});

const selectOrderLimit = createSelector([selectRaw], (raw) => {
  const pagination = raw.orderPagination;
  return pagination.pageSize;
});

const selectOrderOffset = createSelector([selectRaw], (raw) => {
  const pagination = raw.orderPagination;

  if (!pagination || !pagination.pageSize) {
    return 0;
  }

  const current = pagination.current || 1;

  return current - 1;
});

// paginacion tabla de turnos asignados por id header consolidado

const selectTurnById = createSelector([selectRaw], (raw) => raw.turnsId);

const selectHasRowsTurnById = createSelector(
  [selectRaw],
  (raw) => raw.countTurnById
);

const selectLimitTurnById = createSelector([selectRaw], (raw) => {
  const pagination = raw.paginationTurnById;
  return pagination.pageSize;
});

const selectCountById = createSelector([selectRaw], (raw) => raw.countTurnById);

const selectPaginationTurnById = createSelector(
  [selectRaw, selectCountById],
  (raw, count) => {
    return {
      ...raw.paginationTurnById,
      total: count,
    };
  }
);

const selectOffsetTurnById = createSelector([selectRaw], (raw) => {
  const pagination = raw.paginationTurnById;

  if (!pagination || !pagination.pageSize) {
    return 0;
  }

  const current = pagination.current || 1;

  return current - 1;
});

const selectSorterTurnById = createSelector(
  [selectRaw],
  (raw) => raw.sorterTurnById || {}
);

const selectOrderByTurnById = createSelector([selectRaw], (raw) => {
  const sorter = raw.sorterTurnById;

  if (!sorter) {
    return null;
  }

  if (!sorter.field) {
    return null;
  }

  let direction = sorter.order === "desc" ? "DESC" : "ASC";

  return `${sorter.field},${direction}`;
});

const selectOrderCount = createSelector([selectRaw], (raw) => raw.orderCount);

const selectHasOrderRows = createSelector(
  [selectRaw],
  (raw) => raw.orderCount > 0
);

const selectOrderPagination = createSelector(
  [selectRaw, selectCount],
  (raw, count) => {
    return {
      ...raw.orderPagination,
      total: count,
    };
  }
);

const selectConsolidatedByTurnId = createSelector(
  [selectRaw],
  (raw) => raw.articlesOrTrucksByTurn
);

const selectDataByTurnId = createSelector(
  [selectRaw],
  (raw) => raw.getDataByTurn
);

// ---- selectores para tabla mercaderias/camiones ----
const selectCountTrucks = createSelector([selectRaw], (raw) => raw.countTrucks);

const selectPaginationTrucks = createSelector(
  [selectRaw, selectCountTrucks],
  (raw, count) => {
    return {
      ...raw.paginationTrucks,
      total: count,
    };
  }
);

const selectSorterTrucks = createSelector(
  [selectRaw],
  (raw) => raw.sorter || {}
);

const selectOffsetTrucks = createSelector([selectRaw], (raw) => {
  const pagination = raw.paginationTrucks;

  if (!pagination || !pagination.pageSize) {
    return 0;
  }

  const current = pagination.current || 1;

  return current - 1;
});

const selectLimitTrucks = createSelector([selectRaw], (raw) => {
  const pagination = raw.paginationTrucks;
  return pagination.pageSize;
});

const selectFilterTrucks = createSelector([selectRaw], (raw) => {
  return raw.filter;
});

const selectOrderByTrucks = createSelector([selectRaw], (raw) => {
  const sorter = raw.sorter;

  if (!sorter) {
    return null;
  }

  if (!sorter.field) {
    return null;
  }

  let direction = sorter.order === "desc" ? "DESC" : "ASC";

  return `${sorter.field},${direction}`;
});

const selectLoadingTransport = createSelector([selectRaw], (raw) => raw.loadingTransport);

const selectLoadingCreateRemito = createSelector([selectRaw], (raw) => raw.loadingCreateRemito);

const selectConsolidatedSVA = createSelector([selectRaw], (raw) => raw.consolidatedSVA);

// ---- selectores para tabla remitos ----
const selectCountRemitos = createSelector(
  [selectRaw],
  (raw) => raw.countRemitos
);

const selectPaginationRemitos = createSelector(
  [selectRaw, selectCountRemitos],
  (raw, count) => {
    return {
      ...raw.paginationRemitos,
      total: count,
    };
  }
);

const selectSorterRemitos = createSelector(
  [selectRaw],
  (raw) => raw.sorter || {}
);

const selectOffsetRemitos = createSelector([selectRaw], (raw) => {
  const pagination = raw.paginationRemitos;

  if (!pagination || !pagination.pageSize) {
    return 0;
  }

  const current = pagination.current || 1;

  return current - 1;
});

const selectLimitRemitos = createSelector([selectRaw], (raw) => {
  const pagination = raw.paginationRemitos;

  return pagination.pageSize;
});

const selectFilterRemitos = createSelector([selectRaw], (raw) => {
  return raw.filter;
});

const selectOrderByRemitos = createSelector([selectRaw], (raw) => {
  const sorter = raw.sorter;

  if (!sorter) {
    return null;
  }

  if (!sorter.field) {
    return null;
  }

  let direction = sorter.order === "desc" ? "DESC" : "ASC";

  return `${sorter.field},${direction}`;
});

const selectSorterTurn = createSelector(
  [selectRaw],
  (raw) => raw.turnSorter || {}
);

const selectOrderByTurn = createSelector([selectRaw], (raw) => {
  const sorter = raw.turnSorter;

  if (!sorter) {
    return null;
  }

  if (!sorter.field) {
    return null;
  }

  let direction = sorter.order === "desc" ? "DESC" : "ASC";

  return `${sorter.field},${direction}`;
});

const selectLimitTurn = createSelector([selectRaw], (raw) => {
  const pagination = raw.paginationTurn;
  return pagination.pageSize;
});

const selectOffsetTurn = createSelector([selectRaw], (raw) => {
  const pagination = raw.paginationTurn;

  if (!pagination || !pagination.pageSize) {
    return 0;
  }

  const current = pagination.current || 1;

  return current - 1;
});

const selectFilterTurn = createSelector([selectRaw], (raw) => {
  return raw.turnFilter;
});

const selectPaginationTurn = createSelector(
  [selectRaw, selectCountTurnConsolidated],
  (raw, count) => {
    return {
      ...raw.paginationTurn,
      total: count,
    };
  }
);
//EMBARQUES

const selectCountEmbarque = createSelector(
  [selectRaw],
  (raw) => raw.countEmbarque
);
const selectFilesEmbarques = createSelector(
  [selectRaw],
  (raw) => raw.filesEmbarques
);

const getFechaFormateada = (fechaConsolidacion) => {

  // Convertir a objeto de fecha
  var fechaObjeto = new Date(fechaConsolidacion + "T00:00:00Z");

  // Obtener la diferencia horaria en minutos
  var diferenciaHoraria = fechaObjeto.getTimezoneOffset();

  // Aplicar la diferencia horaria para corregir
  fechaObjeto.setMinutes(fechaObjeto.getMinutes() + diferenciaHoraria);

  // Obtener día, mes y año en formato local
  var dia = fechaObjeto.getDate();
  var mes = fechaObjeto.getMonth() + 1; // Sumar 1 porque los meses van de 0 a 11
  var año = fechaObjeto.getFullYear();

  // Formatear como fecha española
  var fechaFormateada = dia + "/" + mes + "/" + año;

  return  fechaFormateada;
}

const	getPermisosEmbarque	=	(arra)	=>	{
	return	arra.map(({consolidadoHeaderDTO,	numero, tipo,  fechaConsolidacion,  pesada})	=>	({
		companyName: consolidadoHeaderDTO	?	consolidadoHeaderDTO.clientCompany.companyName	:	"no-data",
		booking:	consolidadoHeaderDTO	?	consolidadoHeaderDTO.booking	:	"no-data",
		numero,
		tipo,
		consolidacion: fechaConsolidacion && tipo === "DEPOSITO" ? getFechaFormateada(fechaConsolidacion) : tipo,
    pesada: pesada ? "Si" : "No"
	}))
}

const selectFilesEmbarquesCsv = createSelector(
  [selectRaw],
  ({filesEmbarques}) => {

    const columnsHeader =  [
      {id:"companyName", displayName:  "Exportador"},
      {id:"booking", displayName:  "Booking"},
      {id:"numero", displayName:  "Permiso de Embarque	"},
      {id:"tipo", displayName:  "Tipo de Operación"},
      {id:"consolidacion", displayName:  "Consolidación"},
      {id:"pesada", displayName:  "Pesada"},
      ]
      
    const data  = getPermisosEmbarque(filesEmbarques);
  
    return	[data,  columnsHeader];
  });

const selectLoadingFilesEmbarques = createSelector(
  [selectRaw],
  (raw) => raw.loadingFilesEmbarques
);
const selectSaveLoadingEmbarque = createSelector(
  [selectRaw],
  (raw) => raw.saveLoadingEmbarque
);

const selectLimitEmbarque = createSelector([selectRaw], (raw) => {
  const pagination = raw.paginationEmbarque;
  return pagination.pageSize;
});

const selectOffsetEmbarque = createSelector([selectRaw], (raw) => {
  const pagination = raw.paginationEmbarque;

  if (!pagination || !pagination.pageSize) {
    return 0;
  }

  const current = pagination.current || 1;

  return current - 1;
});

const selectOrderByEmbarque = createSelector([selectRaw], (raw) => {
  const sorter = raw.sorterEmbarque;

  if (!sorter) {
    return null;
  }

  if (!sorter.field) {
    return null;
  }

  let direction = sorter.order === "desc" ? "DESC" : "ASC";

  return `${sorter.field},${direction}`;
});

const selectFilterEmbarque = createSelector([selectRaw], (raw) => {
  return raw.filterEmbarque;
});
const selectSorterEmbarque = createSelector(
  [selectRaw],
  (raw) => raw.sorterEmbarque || {}
);
const selectPaginationEmbarque = createSelector(
  [selectRaw, selectCountEmbarque],
  (raw, count) => {
    return {
      ...raw.paginationEmbarque,
      total: count,
    };
  }
);

const selectLoadingRemito = createSelector(
  [selectRaw],
  (raw) => raw.loadingRemito
);

const selectSelectConsolidationTurn = createSelector(
  [selectRaw],
  (raw) => raw.selectConsolidationTurn
);

const selectLoadingConsolidationTurn = createSelector(
  [selectRaw],
  (raw) => raw.loadingConsolidationTurn
);

const consolidatedListSelectors = {
  selectLoading,
  selectInitLoading,
  selectLoadingUsers,
  selectInitLoadingUsers,
  selectRows,
  selectRowsCsv,
  selectCount,
  selectRowsTurnConsolidated,
  selectRowsTurnConsolidatedCsv,
  selectCountTurnConsolidated,
  selectRowsUserConsolidated,
  selectCountUserConsolidated,
  selectHasRows,
  selectHasRowsRemito,
  selectSorter,
  selectOrderBy,
  selectLimit,
  selectFilter,
  selectOffset,
  selectPagination,
  selectHasOrderRows,
  selectRawFilter,
  selectOrderCount,
  selectCurrentOrderBy,
  selectFilterOrder,
  selectFilterOperation,
  selectOrderLimit,
  selectOrderOffset,
  selectOrderPagination,
  selectConsolidatedById,
  selectTrucks,
  selectTurnById,
  selectTurns,
  selectRemitos,
  selectLoadingRemito,
  selectTruckById,
  selectorCommodityOptions,
  selectorTransportOptions,
  selectPreStatus,
  selectStatus,
  selectPackaging,
  selectorOrderPackaging,
  selectTime,
  selectorOrderTime,
  selectTurnId,
  selectFiles,
  selectServices,
  selectConsolidatedByTurnId,
  selectCountTrucks,
  selectOrderByTrucks,
  selectLimitTrucks,
  selectOffsetTrucks,
  selectFilterTrucks,
  selectSorterTrucks,
  selectPaginationTrucks,
  selectCountRemitos,
  selectOrderByRemitos,
  selectLimitRemitos,
  selectOffsetRemitos,
  selectFilterRemitos,
  selectSorterRemitos,
  selectPaginationRemitos,
  selectStatusConsolidated,
  selectLimitTurn,
  selectOrderByTurn,
  selectOffsetTurn,
  selectFilterTurn,
  selectPaginationTurn,
  selectSorterTurn,
  selectHasRowsTurn,
  selectDataByTurnId,
  validationHeader,
  selectPaginationTurnById,
  selectLimitTurnById,
  selectOffsetTurnById,
  selectOrderByTurnById,
  selectSorterTurnById,
  selectHasRowsTurnById,
  selectRowsOperacionesTipo,
  selectLoadingOperacionesTipo,
  selectLoadingTurnConsolidated,
  selectLoadingTurnId,
  selectLoadingTransport,
  selectLoadingCreateRemito,
  selectConsolidatedSVA,

  //EMBARQUES
  selectCountEmbarque,
  selectFilesEmbarques,
  selectFilesEmbarquesCsv,
  selectLoadingFilesEmbarques,
  selectSaveLoadingEmbarque,
  selectFilterEmbarque,
  selectLimitEmbarque,
  selectOffsetEmbarque,
  selectOrderByEmbarque,
  selectSorterEmbarque,
  selectPaginationEmbarque,

  selectSelectConsolidationTurn,
  selectLoadingConsolidationTurn,
};

export default consolidatedListSelectors;
