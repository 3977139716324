import Errors from "src/modules/shared/error/errors";
import service from "../expoFullService";
import { getHistory } from "src/modules/store";
import Message from "src/view/shared/message";
import expoFullListAction from "../list/expoFullListAction";
import generalListSelectors from "src/modules/shared/generals/list/GeneralListSelectors";
import moment from "moment";

const prefix = "EXPO_FULL_FORM";
const FORMAT_TIME = "HH:mm:ss";
const FORMAT_DATE = "YYYY-MM-DD";

const expoFullFormAction = {
  //EDICION DE FULL BODY
  HEADER_CREATE_STARTED: `${prefix}_HEADER_CREATE_STARTED`,
  HEADER_CREATE_SUCCESS: `${prefix}_HEADER_CREATE_SUCCESS`,
  HEADER_CREATE_ERROR: `${prefix}_HEADER_CREATE_ERROR`,

  //EDICION DE FULL BODY
  RESERVATION_STARTED: `${prefix}_RESERVATION_STARTED`,
  RESERVATION_SUCCESS: `${prefix}_RESERVATION_SUCCESS`,
  RESERVATION_ERROR: `${prefix}_RESERVATION_ERROR`,

  //EDICION DE FULL BODY
  EDIT_FULL_BODY_STARTED: `${prefix}_EDIT_FULL_BODY_STARTED`,
  EDIT_FULL_BODY_SUCCESS: `${prefix}_EDIT_FULL_BODY_SUCCESS`,
  EDIT_FULL_BODY_ERROR: `${prefix}_EDIT_FULL_BODY_ERROR`,

  //CREACION DE FULL BODY
  CREATE_FULL_BODY_STARTED: `${prefix}_CREATE_FULL_BODY_STARTED`,
  CREATE_FULL_BODY_SUCCESS: `${prefix}_CREATE_FULL_BODY_SUCCESS`,
  CREATE_FULL_BODY_ERROR: `${prefix}_CREATE_FULL_BODY_ERROR`,

  //ENVIO DE PREAVISO
  PREAVISO_STARTED: `${prefix}_PREAVISO_STARTED`,
  PREAVISO_SUCCESS: `${prefix}_PREAVISO_SUCCESS`,
  PREAVISO_ERROR: `${prefix}_PREAVISO_ERROR`,

  createFormExpoFull: (data) => async (dispatch) => {
    try {
      await service.createFormExpoFull(data);
      //dispatch(expoFullFormAction.doFetch());
    } catch (error) {
      Errors.handle(error);
    }
  },
  cancelFormFull: (data) => async (dispatch) => {
    try {
      await service.cancelFormFull(data);
      //dispatch(expoFullListAction.doFetch());
    } catch (error) {
      Errors.handle(error);
    }
  },

  createFullHeader: (data) => async (dispatch) => {
    try {
      dispatch({
        type: expoFullFormAction.HEADER_CREATE_STARTED,
      });

      const response = await service.createHeaderFull(data);

      dispatch({
        type: expoFullFormAction.HEADER_CREATE_SUCCESS,
        payload: response,
      });
      Message.success(response?.message);
      getHistory().push(`/turns/exportfull/request/${response.data.id}`);
    } catch (error) {
      Errors.showMessage(error);
      dispatch({
        type: expoFullFormAction.HEADER_CREATE_ERROR,
      });
    }
  },

  createFullBody: (data, setPdf?, setOpen?) => async (dispatch) => {
    try {
      setOpen(false);
      dispatch({
        type: expoFullFormAction.CREATE_FULL_BODY_STARTED,
      });
      data.cntNbr = data?.cntNbr.toUpperCase();
      const response = await service.createFullBody(data);
      setOpen(true);
      dispatch({
        type: expoFullFormAction.CREATE_FULL_BODY_SUCCESS,
        payload: response.data,
      });

      Message.success(`${response.message}`);
      setPdf(true);
    } catch (error) {
      Errors.showMessage(error);
      setPdf(false);
      dispatch({
        type: expoFullFormAction.CREATE_FULL_BODY_ERROR,
      });
    }
  },

  createReservationFullBody:
    (data, validationCutoff) => async (dispatch, getState) => {
      try {
        const { vessel_time_cargo_cutoff: cutoff } =
          generalListSelectors.selectorBookingNavis(getState());
        const [date, time] = cutoff.split(" ");
        const {from} = data;
        const dateCutoff = moment(date).format( FORMAT_DATE);
        const timeCutoff = moment(time, FORMAT_TIME);
        const timeFrom = moment(from, FORMAT_TIME);

        const validationCuttffDate =
          validationCutoff &&
          dateCutoff === moment(data.date).format(FORMAT_DATE);
        
        const response = await service.createReservationFullBody({
        ...data,
          cutOffDate: moment(cutoff, `${FORMAT_DATE} ${FORMAT_TIME}`).format('DD/MM/YYYY HH:mm'),
          validationCuttffDate,
        });
        dispatch({
          type: expoFullFormAction.RESERVATION_SUCCESS,
          payload: response,
        });

        getHistory().push(`/turns/exportfull/request/${data.idHeader}`);
        Message.success(`${response.message}`);
      } catch (error) {
        Errors.handle(error);
      }
    },

  deleteHeader: (data?) => async (dispatch, getState) => {
    try {
      const response = await service.deleteHeader(data);
      dispatch(expoFullListAction.doFetch());
      Message.success(`${response.message}`);
    } catch (error) {
      Message.error(`Eliminar contenedores antes de eliminar la operación`);
    }
  },
  deleteBody: (data?) => async (dispatch, getState) => {
    try {
      const response = await service.deleteBody(data);
      dispatch(
        expoFullListAction.findFullBodyByFullHeaderId(data.fullHeaderId)
      );
      dispatch(expoFullListAction.findFullHeaderById(data.fullHeaderId));
      Message.success(`${response.message}`);
    } catch (error) {
      Errors.showMessage(error);
    }
  },
  scheduledOther: (data?) => async (dispatch, getState) => {
    try {
      const response = await service.scheduledOtherFull(data);
      getHistory().push(`/turns/exportfull/request/${data.idHeader}`);
      Message.success(`${response.message}`);
    } catch (error) {
      Errors.showMessage(error);
    }
  },

  preAviso:
    (id, files, handleClose, idHeader, setOpenModal?, setOpen?) =>
    async (dispatch, getState) => {
      try {
        setOpenModal(false);
        setOpen(false);
        dispatch({
          type: expoFullFormAction.PREAVISO_STARTED,
        });
        const response = await service.preAviso(id, files);
        dispatch(expoFullListAction.findFullBodyByFullHeaderId(idHeader));
        dispatch({
          type: expoFullFormAction.PREAVISO_SUCCESS,
        });
        Message.success(`${response.message}`);
        handleClose();
      } catch (error) {
        Errors.showMessage(error);
        dispatch({
          type: expoFullFormAction.PREAVISO_ERROR,
        });
        dispatch(expoFullListAction.findFullBodyByFullHeaderId(idHeader));
        handleClose();
      }
    },

  createContainer:
    (nroContainer, handleClose) => async (dispatch, getState) => {
      try {
        const response = await service.createContianer(nroContainer);
        Message.success(`${response.message}`);
        handleClose();
      } catch (error) {
        Errors.showMessage(error);
      }
    },

  editFullBody:
    (data, setPdf?, setSelectedValue?, setEditData?, setOpen?) =>
    async (dispatch) => {
      try {
        setOpen(false);
        dispatch({
          type: expoFullFormAction.EDIT_FULL_BODY_STARTED,
        });
        const response = await service.EditFullBody(data);
        setOpen(true);
        dispatch({
          type: expoFullFormAction.EDIT_FULL_BODY_SUCCESS,
          payload: response.data,
        });

        Message.success(`${response.message}`);
        setOpen(true);
        setPdf(true);
        setEditData(response.data);
        setSelectedValue({
          grossWeight: Boolean(response?.data?.grossWeight),
          loadWeight: Boolean(response?.data?.loadWeight),
          data: Boolean(response?.data?.grossWeight)
            ? { grossWeight: response?.data?.grossWeight }
            : { loadWeight: response?.data?.loadWeight },
          valor: response?.data?.grossWeight || response?.data?.loadWeight,
        });
      } catch (error) {
        Errors.showMessage(error);
        dispatch({
          type: expoFullFormAction.EDIT_FULL_BODY_ERROR,
        });
      }
    },
};

export default expoFullFormAction;
