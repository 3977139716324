import { createSelector } from 'reselect';

const selectRaw = (state) => state.stakeholderBooking.list;

const selectLoading = createSelector([selectRaw], (raw) => raw.loading);

const selectRows = createSelector([selectRaw], (raw) => raw.rows);

const	getBookings	=	(arra)	=>	{
	return	arra.map(({value,	clientCompany,  line, buque,  containerQuantity, stakeholder, modifiedBy, createdAt,  status})	=>	({
		companyName: clientCompany	?	clientCompany.companyName	:	"no-data",
		value,
		line,
		buque:	buque	?	buque	:	"no-data",
		containerQuantity:	containerQuantity	?	containerQuantity	:	"no-data",
		status,
		stakeholder:	stakeholder	?	stakeholder.companyName	:	"no-data",
		modifiedBy:	modifiedBy	?	modifiedBy	:	"no-data",
		createdAt,
	}))
}

const selectRowsCsv = createSelector([selectRaw], ({rows}) => {
	const columnsHeader =  [
		{id:"companyName", displayName:  "Exportador"},
		{id:"value", displayName:  "Booking"},
		{id:"line", displayName:  "Lines"},
		{id:"buque", displayName:  "Buque"},
		{id:"containerQuantity", displayName:  "Contenedores Totales"},
		{id:"status", displayName:  "Estado"},
		{id:"stakeholder", displayName:  "Despachante"},
		{id:"horarioTentativo", displayName:  "Asignado Por"},
		{id:"createdAt", displayName:  "Desde"},
	  ]
	  
  const data  = getBookings(rows);

  return	[data,  columnsHeader];

});

const selectMessage = createSelector([selectRaw], (raw) => raw.message);

const selectCount = createSelector([selectRaw], (raw) => raw.count);

const selectHasRows = createSelector([selectRaw], (raw) => raw.rows.length > 0);

//si
const selectOrderBy = createSelector([selectRaw], (raw) => {
	const sorter = raw.sorter;

	if (!sorter) {
		return null;
	}

	if (!sorter.field) {
		return null;
	}

	let direction = sorter.order === 'desc' ? 'DESC' : 'ASC';

	return `${sorter.field},${direction}`;
});
//si
const selectFilter = createSelector([selectRaw], (raw) => {
	return raw.filter;
});
//si
const selectLimit = createSelector([selectRaw], (raw) => {
	const pagination = raw.pagination;
	return pagination.pageSize;
});
//si
const selectOffset = createSelector([selectRaw], (raw) => {
	const pagination = raw.pagination;

	if (!pagination || !pagination.pageSize) {
		return 0;
	}

	const current = pagination.current || 1;

	return current - 1;
});

const selectPagination = createSelector([selectRaw, selectCount], (raw, count) => {
	return {
		...raw.pagination,
		total: count,
	};
});

const selectStatus = createSelector([selectRaw], ({ status }) => status.map(({value,label}) => ({ value:value, label:label })).sort((a, b) => a.label?.localeCompare(b.label)));

const selectorClientCompanies = createSelector([selectRaw], (raw)=> raw.client)

const selectCompanies = createSelector([selectorClientCompanies], ({ data })=> !Boolean(data)? []: data
	.map(({cuit,companyName, id}) => ({ value:cuit, label:companyName, id })));

const selectStakeholders = createSelector([selectRaw], ({ stakeholders } )=> !Boolean(stakeholders)? []: stakeholders
.map(({id,companyName}) => ({ value:id, label:companyName })));

const selectSorter = createSelector([selectRaw], (raw) => raw.sorter || {});

const getBooking = createSelector(
	[selectRaw],
	(raw) => raw.row
  );

const stakeholderBookingSelectors = {
	selectOrderBy,
	selectLimit,
	selectOffset,
	selectFilter,
	selectPagination,
	selectCount,
	selectRows,
	selectRowsCsv,
	selectHasRows,
	selectLoading,
	selectStatus,
	selectCompanies,
	selectStakeholders,
	selectSorter,
	selectMessage,
	getBooking
};

export default stakeholderBookingSelectors;
