import { createSelector } from "reselect";

const selectRaw = (state) => state.empty.list;

const selectLoading = createSelector([selectRaw], (raw) => raw.loading);

const selectLoadingBookingForwarder = createSelector([selectRaw], (raw) => raw.loadingBookingForwarder);

const selectLoadingHeader = createSelector([selectRaw], (raw) => raw.loadingHeader); //prueba de header

const selectLoadingDelegation = createSelector(
  [selectRaw],
  (raw) => raw.loadingDelegation
);

const selectRows = createSelector([selectRaw], (raw) => raw.rows);

const selectRowsBody = createSelector([selectRaw], (raw) => raw.bodyByIdHeader);

const selectStatusHeader = createSelector(
  [selectRaw],
  (raw) => raw.statusHeader
);

const selectStatusHeaderOptions = createSelector(
  [selectStatusHeader],
  (state) =>
    !Boolean(state)
      ? []
      : state.map((s: any) => ({ value: s, label: s.replace(/_/g, " ") }))
);

const selectEmptyHeaderRows = createSelector(
  [selectRaw],
  (raw) => raw.emptyHeader
);

const selectCount = createSelector([selectRaw], (raw) => raw.count);

const selectCountBody = createSelector([selectRaw], (raw) => raw.bodyCount);

const selectHasRows = createSelector([selectRaw], (raw) => raw.count > 0);

const selectHasRowsBody = createSelector(
  [selectRaw],
  (raw) => raw.bodyCount > 0
);

const selectSorter = createSelector([selectRaw], (raw) => raw.sorter || {});

const selectSorterBody = createSelector(
  [selectRaw],
  (raw) => raw.sorterBody || {}
);

const selectorEmpty = createSelector([selectRaw], (raw) => raw.emptyHeader);

const contDisp = (numberOfContainers, tallyOut, contenedoresSolicitado, operationId) => {
  const result1 = numberOfContainers - tallyOut - contenedoresSolicitado;
  const result2 = numberOfContainers - contenedoresSolicitado;
  if (operationId === 1 && result1 > 0) return result1;
  if (operationId === 1 && result1 === 0) return "0";
  if (operationId === 2 && result2 > 0) return result2;
  return "0";
};

const getEmptyHeaders = (arra) => {
  return arra.map(({ operation, clientCompany, bookingNumber, buque, line, attachments, numberOfContainers, contenedoresSolicitado, status, tallyOut, operationId }) => ({
    name: operation ? operation.name : "no-data",
    companyName: clientCompany ? clientCompany.companyName : "no-data",
    bookingNumber,
    status,
    line,
    buque,
    attachments: attachments.length > 0 ? "si" : "no",
    numberOfContainers,
    contenedoresSolicitado,
    tallyOut: tallyOut ? tallyOut : 0,
    contenedoresDisponibles: contDisp(numberOfContainers, tallyOut, contenedoresSolicitado, operationId),
  }))
}

const selectorRowsCsv = createSelector([selectRaw], ({ emptyHeader }) => {
  const columnsHeader = [
    { id: "name", displayName: "Tipo de Operacion" },
    { id: "companyName", displayName: "Exportador" },
    { id: "bookingNumber", displayName: "Booking" },
    { id: "status", displayName: "Estado" },
    { id: "line", displayName: "Linea" },
    { id: "buque", displayName: "Buque" },
    { id: "attachments", displayName: "Documentacion" },
    { id: "numberOfContainers", displayName: "Contenedores Totales" },
    { id: "contenedoresSolicitado", displayName: "Contenedores Solicitados" },
    { id: "tallyOut", displayName: "Contenedores Retirados" },
    { id: "contenedoresDisponibles", displayName: "Contenedores Disponibles" },
  ]

  const data = getEmptyHeaders(emptyHeader);

  return [data, columnsHeader];
});

const selectorNewEmptyHeader = createSelector(
  [selectRaw],
  (raw) => raw.newEmptyHeader
);

const selectorHeaderById = createSelector([selectRaw], (raw) => raw.headerById);

const selectorBodyListByHeaderId = createSelector(
  [selectRaw],
  (raw) => raw.emptyBodyList
);

const selectorClientCompanies = createSelector(
  [selectRaw],
  (raw) => raw.client
);

const selectorBookingNavis = createSelector([selectRaw], (raw) => raw.booking);

const selectCantContainerBooking = createSelector(
  [selectorBookingNavis],
  (raw) =>
    raw.items?.itemList.reduceRight((total, item) => total + item.quantity, 0)
);


const selectTypes = createSelector([selectRaw], ({ types }) => types);

const selectStatus = createSelector([selectRaw], ({ status }) =>
  status
    .map(({ value, label }) => ({ value: value, label: label }))
    .sort((a, b) => a?.label?.localeCompare(b.label))
);

const selectCuitClient = createSelector([selectRaw], (raw) => raw.cuitClient);

const selectCuitClientMessage = createSelector(
  [selectRaw],
  (raw) => raw.cuitClientMessage
);
const selectCuitClientCode = createSelector(
  [selectRaw],
  (raw) => raw.cuitClientCode
);
const selectCuitClientDataRZ = createSelector(
  [selectRaw],
  (raw) => raw.cuitClientDataRZ
);


const selectMessageNewBody = createSelector(
  [selectRaw],
  (raw) => raw.emptyBody
);

const selectMessageEditBody = createSelector(
  [selectRaw],
  (raw) => raw.editEmptyBody
);

const selectStakeholder = createSelector(
  [selectRaw],
  (raw) => raw.stakeholders
);

const selectStakeholderNoPagination = createSelector(
  [selectRaw],
  (raw) => raw.stakeholdersNoPagination
);


const selectStakeholders = createSelector([selectRaw], ({ stakeholders }) =>
  !Boolean(stakeholders)
    ? []
    : stakeholders
      ?.map(({ id, companyName, cuit }) => ({
        value: id,
        label: companyName,
        cuit: cuit,
      }))
      .sort((a, b) => a?.label?.localeCompare(b.label))
);

const selectStakeholderByCompanyId = createSelector(
  [selectRaw],
  ({ stakeholderByCompanyId }) =>
    !Boolean(stakeholderByCompanyId)
      ? []
      : stakeholderByCompanyId
        ?.map(({ id, companyName }) => ({ value: id, label: companyName }))
        .sort((a, b) => a?.label?.localeCompare(b.label))
);

const selectStakeholdersModified = createSelector(
  [selectRaw],
  ({ stakeholders }) => {
    let modifiedStakeholders = [] as any;

    if (Boolean(stakeholders)) {
      modifiedStakeholders = stakeholders.map(({ id, companyName }) => ({
        value: id,
        label: companyName,
      }));
    }

    modifiedStakeholders.unshift({
      value: true,
      label: "Sin despachante asignado",
    });

    //   return modifiedStakeholders.sort((a, b) => a.label?.localeCompare(b.label));
    return modifiedStakeholders;
  }
);

const selectStakeholdersModifiedNotPagination = createSelector(
  [selectRaw],
  ({ stakeholdersNoPagination }) => {
    let modifiedStakeholders = [] as any;

    if (Boolean(stakeholdersNoPagination)) {
      modifiedStakeholders = stakeholdersNoPagination.map(({ id, companyName }) => ({
        value: id,
        label: companyName,
      }));
    }

    modifiedStakeholders.unshift({
      value: '',
      label: "Sin despachante asignado",
    });

    return modifiedStakeholders.sort((a, b) => a.label?.localeCompare(b.label));
    return modifiedStakeholders;
  }
);


const bookingForwarder = createSelector(
  [selectRaw],
  (raw) => raw.bookingForwarder
);

const selectorOrders = createSelector([selectRaw], (raw) => raw.orders);

const selectorLines = createSelector([selectRaw], (raw) => raw.lines)

const selectorOrdersNotPagination = createSelector([selectRaw], (raw) => raw.ordersNotPagination);

const selectErrorNavis = createSelector([selectRaw], (raw) => raw.bookingError);

const forwarderBooking = createSelector(
  [selectRaw],
  (raw) => raw.forwarderBooking
);

const selectorOrdersOptions = createSelector([selectorOrders], (order) =>
  order.map(({ value }) => ({
    value,
    label: value,
  }))
);

const selectorLinesOptions = createSelector([selectorLines], (lines) =>
  lines.map(({ name }) => ({
    value: name,
    label: name,
  }))
)

const selectorOrdersNotPaginationObj = createSelector([selectorOrdersNotPagination], (order) =>
  order.map(({ value }) => ({
    value,
    label: value,
  }))
);

const selectOrderBy = createSelector([selectRaw], (raw) => {
  const sorter = raw.sorter;

  if (!sorter) {
    return null;
  }

  if (!sorter.field) {
    return null;
  }

  let direction = sorter.order === "desc" ? "DESC" : "ASC";

  return `${sorter.field},${direction}`;
});

const selectOrderByBody = createSelector([selectRaw], (raw) => {
  const sorter = raw.sorterBody;

  if (!sorter) {
    return null;
  }

  if (!sorter.field) {
    return null;
  }

  let direction = sorter.order === "desc" ? "DESC" : "ASC";

  return `${sorter.field},${direction}`;
});

const selectFilter = createSelector([selectRaw], (raw) => {
  return raw.filter;
});

const selectFilterLabel = createSelector([selectRaw], (raw) => {
  return raw.filterLabel;
});
const selectLimit = createSelector([selectRaw], (raw) => {
  const pagination = raw.pagination;
  return pagination.pageSize;
});

const selectLimitBody = createSelector([selectRaw], (raw) => {
  const pagination = raw.paginationBody;
  return pagination.pageSize;
});

const selectOffset = createSelector([selectRaw], (raw) => {
  const pagination = raw.pagination;

  if (!pagination || !pagination.pageSize) {
    return 0;
  }

  const current = pagination.current || 1;

  return current - 1;
});

const selectOffsetBody = createSelector([selectRaw], (raw) => {
  const pagination = raw.paginationBody;

  if (!pagination || !pagination.pageSize) {
    return 0;
  }

  const current = pagination.current || 1;

  return current - 1;
});

const selectPagination = createSelector(
  [selectRaw, selectCount],
  (raw, count) => {
    return {
      ...raw.pagination,
      total: count,
    };
  }
);

const selectPaginationBody = createSelector(
  [selectRaw, selectCountBody],
  (raw, count) => {
    return {
      ...raw.paginationBody,
      total: count,
    };
  }
);

const selectCurrentOrderBy = createSelector([selectRaw], (raw) => {
  const sorter = raw.sorterOrder;

  if (!sorter) {
    return null;
  }

  if (!sorter.field) {
    return null;
  }

  let direction = sorter.order === "desc" ? "DESC" : "ASC";

  return `${sorter.field},${direction}`;
});

const selectFilterOrder = createSelector([selectRaw], (raw) => {
  return raw.orderFilter;
});

const selectOrderLimit = createSelector([selectRaw], (raw) => {
  const pagination = raw.orderPagination;
  return pagination.pageSize;
});

const selectOrderOffset = createSelector([selectRaw], (raw) => {
  const pagination = raw.orderPagination;

  if (!pagination || !pagination.pageSize) {
    return 0;
  }

  const current = pagination.current || 1;

  return current - 1;
});

const selectOrderCount = createSelector([selectRaw], (raw) => raw.orderCount);

const selectHasOrderRows = createSelector(
  [selectRaw],
  (raw) => raw.orderCount > 0
);

const selectOrderPagination = createSelector(
  [selectRaw, selectCount],
  (raw, count) => {
    return {
      ...raw.orderPagination,
      total: count,
    };
  }
);

const selectOrderSorter = createSelector(
  [selectRaw],
  (raw) => raw.orderSorter || {}
);

const selectorAttachments = createSelector(
  [selectRaw],
  (raw) => raw.attachments
);

const selectorContainer = createSelector([selectRaw], (raw) => raw.container.map(({ height, iso_group, length }) => ({
  value: `${iso_group} ${height} ${length}`,
  label: `${iso_group} ${height} ${length}`
})));

const selectLoadingCompanies = createSelector([selectRaw], (raw) => raw.loadingCompanies);

const emptyListSelectors = {
  selectLoading,
  selectRows,
  selectorRowsCsv,
  selectCount,
  selectHasRows,
  selectSorter,
  selectOrderBy,
  selectLimit,
  selectFilter,
  selectFilterLabel,
  selectOffset,
  selectPagination,
  selectorEmpty,
  selectorNewEmptyHeader,
  selectorHeaderById,
  selectorBodyListByHeaderId,
  selectorClientCompanies,
  selectorOrders,
  selectEmptyHeaderRows,
  selectHasOrderRows,
  selectOrderCount,
  selectCurrentOrderBy,
  selectFilterOrder,
  selectOrderLimit,
  selectOrderOffset,
  selectOrderPagination,
  selectStakeholders,
  selectStakeholdersModified,
  selectTypes,
  selectStatus,
  selectOrderSorter,
  selectorOrdersOptions,
  selectorBookingNavis,
  selectCuitClient,
  selectCuitClientMessage,
  selectMessageNewBody,
  selectMessageEditBody,
  selectCantContainerBooking,
  bookingForwarder,
  selectorAttachments,
  selectRowsBody,
  selectCountBody,
  selectHasRowsBody,
  selectSorterBody,
  selectPaginationBody,
  selectOrderByBody,
  selectLimitBody,
  selectOffsetBody,
  selectStatusHeaderOptions,
  selectStatusHeader,
  selectErrorNavis,
  forwarderBooking,
  selectStakeholderByCompanyId,
  selectStakeholder,
  selectLoadingDelegation,
  selectStakeholderNoPagination,
  selectStakeholdersModifiedNotPagination,
  selectorContainer,
  selectLoadingCompanies,
  selectorOrdersNotPagination,
  selectorOrdersNotPaginationObj,
  selectLoadingHeader,
  selectLoadingBookingForwarder,
  selectCuitClientCode,
  selectCuitClientDataRZ,
  selectorLines,
  selectorLinesOptions,
};

export default emptyListSelectors;
