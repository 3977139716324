import { ConnectedRouter } from 'connected-react-router';
import { configureStore, getHistory } from 'src/modules/store';
import React, { useEffect, useState } from 'react';
import { Provider, useDispatch } from 'react-redux';
import RoutesComponent from 'src/view/shared/routes/RoutesComponent';
import 'typeface-roboto';
import { CssBaseline, createMuiTheme, MuiThemeProvider, styled } from '@material-ui/core';
import { SnackbarProvider, useSnackbar} from 'notistack';
import Message from 'src/view/shared/message';
import { useIsAuthenticated } from '@azure/msal-react';
import authActions from 'src/modules/auth/authActions';
import { createGlobalStyle } from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { esES } from '@material-ui/core/locale';
import CookiesBanner from './view/auth/CookiesBanner';
import cookieService from 'src/modules/shared/cookies/cookieService'

const GlobalStyles = createGlobalStyle`
  /* Agrega aquí los estilos personalizados del scrollbar */
  ::-webkit-scrollbar {
    width: 4px; /* Ajusta el valor según tu preferencia */
    heigth: 1px;
  }
  
  /* Cambia el ancho del thumb del scrollbar */
  ::-webkit-scrollbar-thumb {
    width: 4px; /* Ajusta el valor según tu preferencia */
    heigth: 1px;
    background-color: #888;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
`;

const useStyles = makeStyles(theme => ({
  success: { backgroundColor: '#007749!important' },
  error: { backgroundColor: '#E41E2D!important' },
  warning: { backgroundColor: '#FF8200!important' },
  info: { backgroundColor: '#004B87!important' },
}));


const store = configureStore();

export default function AuthRoutes(props) {

  return (
    <Provider store={store}>
      <AppWithRedux {...props} />
    </Provider>
  );
}

function AppWithRedux(props) {
  const colors = {
    primary: '#00a695',
    secondary: '#F89829',
    error: "#e41e2d!important",
    disabled: '#BDBDBD',
    text:{
      main:'#333f48'
    },
    success:"#4caf50!important"
    
  };
  const dispatch = useDispatch();

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    dispatch(authActions.doInit());
  }, [isAuthenticated])

  const classes = useStyles()

  const theme = createMuiTheme({
  
    overrides: {
      MuiDialog: {
        paper: {
          padding: "25px"
        },
      },
      MuiDialogContent: {
        root: {
          padding: "4px 6px"
        },
      },
      MuiStepIcon: {
        root: {
          '&$completed': {
            color: colors.secondary,
          },
          '&$active': {
            color: colors.secondary,
          },
        },
        active: {},
        completed: {},
      },
      MuiStepLabel: {
        root: {
          '&$active': {
            color: colors.secondary,
          },
          '&$alternativeLabel': {
            color: colors.secondary,
          },
        },
      },
   
    },

    palette: {
      primary: {
        main: colors.primary,
        
      },
      secondary: {
        main: colors.secondary,
      },
      text: {
        primary: colors.text.main,
        secondary: '#FFFFF',
      },
      error: {
        main: colors.error, // Cambia el color principal de las alertas de éxito
      },
      success:{
        main:colors.success
      }
    
    },
    typography: {
      fontSize: 12.5,
      fontFamily:'Open Sans,sans-serif',
    },
  
  }, esES);

  return (
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={4} classes={{
        variantSuccess:classes.success,
        variantError:classes.error,
        variantInfo:classes.info,
        variantWarning:classes.warning
      }}>
        <>
          <CssBaseline />
          <GlobalStyles />
          <AppWithSnackbar {...props} />
        </>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
}

function AppWithSnackbar(props) {
  const { enqueueSnackbar } = useSnackbar();

 const [showCookiesBanner, setShowCookiesBanner] = useState(false);

  useEffect(() => {
    if (!cookieService.hasAcceptedCookies()) {
      setShowCookiesBanner(true);
    }
  }, []);

  const acceptCookies = () => {
    cookieService.acceptCookies();
    setShowCookiesBanner(false);
  };



  useEffect(() => {
    Message.registerNotistakEnqueueSnackbar(enqueueSnackbar);
  }, [enqueueSnackbar]);

  return (
    <>
      <ConnectedRouter history={getHistory()}>
        <RoutesComponent />
        {showCookiesBanner && (
      <CookiesBanner acceptCookies={acceptCookies} />
      )}
      </ConnectedRouter>
    </>
  );
}
