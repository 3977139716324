import React from "react";
import { Box, Button, Container, Grid, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  cookiesBanner: {
    zIndex: 1300,
    position: "fixed",
    bottom: 0,
    width: "100%",
    minHeight: 130, 
    backgroundColor: "#fff",
    color: "#333f48",
    padding: theme.spacing(2, 3),
    display: "flex",
    alignItems: "center",
    boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
  },
  acceptButton: {
    backgroundColor: "#00a695",
    color: "#fff",
    padding: theme.spacing(1, 3),
    fontSize: "1rem",
    borderRadius: 8,
    "&:hover": {
      backgroundColor: "rgb(0, 116, 104)",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 14
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 11
    },
  },
  link: {
    fontWeight: 600,
    color: "inherit",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  textContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  titleCookies:{
    fontSize: 34,
    fontWeight: 700,
    [theme.breakpoints.down("lg")]: {
      fontSize: 26
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 23
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 20
    },
  },
  subtitleCookies:{
    fontSize: 22,
    [theme.breakpoints.down("lg")]: {
      fontSize: 20
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 17
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 14
    },
  }
}));

export default function CookiesBanner({ acceptCookies }) {
  const classes = useStyles();

  return (
    <Box className={classes.cookiesBanner}>
      {/* <Container> */}
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={11} md={11} lg={11}>
            <Box className={classes.textContainer}>
              <Typography variant="h4" className={classes.titleCookies}>
                Aviso de Cookies
              </Typography>
              <Typography variant="h6" className={classes.subtitleCookies}>
                Este sitio utiliza solo cookies esenciales para asegurar que recibas la mejor experiencia de navegación.{" "}
                <a
                  href="https://www.grupomurchison.com.ar/legales.php"
                  target="_blank"
                  className={classes.link}
                >
                  Ver Legales
                </a>
              </Typography>
            </Box>
          </Grid>
          <Grid container item xs={12} sm={1} lg={1} md={1} justify="flex-end">
            <Button className={classes.acceptButton} onClick={acceptCookies}>
              ACEPTAR
            </Button>
          </Grid>
        </Grid>
    </Box>
  );
}

