import localhost from "src/config/localhost";
import authAxios from "src/modules/shared/axios/authAxios";
import authAxios2 from "src/modules/shared/axios/authAxios2";
import authAxios3 from "src/modules/shared/axios/authAxios3";
import Message from "src/view/shared/message";

export default class ConsolidatedService {
  static async getConsolidatedTurnById(consolidatedTurnId) {
    const response = await authAxios2.get(
      `/portal/consolidado-turn/${consolidatedTurnId}`
    );
    return response.data;
  }

  //consulta de op de expo consolidado por filtro y paginacion
  static async doFetch(orderBy, limit?, offset?, filter?) {
    const obj = {
      sort: orderBy,
      size: limit,
      page: offset,
    };

    const params = Object.assign({}, obj, filter);

    const response = await authAxios2.get(`/portal/consolidado-header`, {
      params,
    });

    return response.data;
  }
  //consulta de op de expo consolidado por filtro y paginacion
  static async doFetchTipoOperacion() {
    const response = await authAxios2.get(`/portal/permisoEmbarque/all-types`);

    return response.data;
  }
  //creacion solicitud de op de expo consolidado con fecha y horario tentativo
  static async createConsolidated(data) {
    const response = await authAxios2.post(`/portal/consolidado-header`, data);
    return response.data;
  }
  //edicion op de expo consolidado
  static async editOperation(data, consolidadoHeaderId?) {
    const response = await authAxios2.put(`/portal/consolidado-header`, data);
    return response.data;
  }
  //eliminar op de expo consolidado
  static async deleteHeaderConsolidado(consolidadoHeaderId) {
    const response = await authAxios2.patch(
      `/portal/consolidado-header/${consolidadoHeaderId}/status/operation/cancel`
    );
    return response.data;
  }

  static async deleteTurnConsolidado(id) {
    const response = await authAxios2.delete(`/portal/consolidado-turn/${id}`);
    return response.data;
  }

  //consulta de op expo consolidado por ID
  static async consolidatedById(consolidadoHeaderId) {
    const headerId = parseInt(consolidadoHeaderId);
    const response = await authAxios2.get(
      `/portal/consolidado-header/${headerId}`
    );
    return response.data;
  }

  static async createClientValidation(data) {
    const response = await authAxios2.post(`/portal/consolidado-header`, data);
    return response.data;
  }

  static async editExpo(id, data) {
    const body = {
      ...data,
    };

    const response = await authAxios.put(
      `/portal/consolidado-header/${id}`,
      body
    );

    return response.data;
  }
  static async getAllExpoFull(orderBy, limit, offset, filter) {
    const params = {
      sort: orderBy,
      size: limit,
      page: offset,
      filter: filter,
    };
    const response = await authAxios2.get(`portal/formFullDelivery/user/23`, {
      params,
    });
    return response.data;
  }
  static async getFullHeader(id) {
    const response = await authAxios2.get(`/portal/consolidado-header/${id}`);
    return response.data;
  }

  static async getAllCommodity() {
    const response = await authAxios2.get(`/portal/commodity/`);
    return response.data;
  }

  static async getAllTypeOfLoad() {
    const response = await authAxios2.get(`/portal/typeOfLoad/`);
    return response.data;
  }

  //endpoint ok
  static async getAllPackaging() {
    const response = await authAxios2.get(`/portal/packaging/`);
    return response.data;
  }

  static async getAllOperations(id) {
    // const response = await authAxios2.get(
    // `/portal/operation/${id || 5}/services`
    // );
    // return response.data;
  }

  static async consolidatedGetTruck(data) {
    const response = await authAxios2.get(
      `/portal/consolidado-truck/consolidado-header/${data}`
    );
    return response.data;
  }
  //consulta turnos dado un id de consolidadoheader
  static async consolidatedGetTurns(consolidadoHeaderId) {
    const response = await authAxios2.get(
      `/portal/consolidado-turn/header/${consolidadoHeaderId}?sort=date%2CASC`
    );
    return response.data;
  }
  //consulta de operacion dado un turn id
  static async getDataConsolidatedByTurnId(consolidadoTurnId) {
    const response = await authAxios2.get(
      `/portal/consolidado-turn/${consolidadoTurnId}`
    );
    return response.data;
  }
  static async consolidatedGetUsers(consolidadoTurnId) {
    const response = await authAxios2.get(
      `/portal/consolidado-turn/${consolidadoTurnId}/recipients`
    );
    return response.data;
  }
  static async consolidatedGetTruckByTruckId(data) {
    const response = await authAxios2.get(`/portal/consolidado-truck/${data}`);
    return response.data;
  }

  //agrgar mercaderia
  static async createConsolidatedTruck(data) {
    const response = await authAxios2.post(`portal/consolidado-truck`, data); //OK
    return response.data;
  }

  //llenar tabla mercaderias por id de turno
  static async getConsolidatedByTurnId(
    consolidadoTurnId,
    orderBy?,
    limit?,
    offset?,
    filter?
  ) {
    const params = {
      sort: orderBy,
      size: limit,
      page: offset,
    };

    const response = await authAxios2.get(
      `/portal/consolidado-truck/turn/${consolidadoTurnId}`,
      { params }
    );
    return response.data;
  }

  static async EditConsolidatedTruck(data) {
    const response = await authAxios2.put(`/portal/consolidado-truck`);
    return response.data;
  }

  static async ApprobationOperation(id, users, pdf) {
    try {
      let formData = new FormData();
      const recipients = JSON.stringify(users);
      const blob = new Blob([recipients], {
        type: "application/json",
      });

      formData.append("recipients", blob);
      formData.append("file", pdf);
      const response = await authAxios3.put(
        `/portal/consolidado-turn/${id}/status/aprobado`,
        formData
      );
      if (response.data.code === "MSCO-EXPRT-LCL2-009-403")
        return Message.error("No se puede aprobar una operación con una mercadería sin remito");
      return response.data;
    } catch(error) {
      throw error
    }
  }

  static async createRemito(data) {
    const response = await authAxios2.post(`portal/consolidado-remito`, data);
    return response.data;
  }

  static async editRemito(data, id?) {
    const obj = {
      ...data,
      descripcion: "hola",
    };

    const response = await authAxios2.put(
      `portal/consolidado-remito/${id}`,
      obj
    );
    return response.data;
  }

  static async getAllRemito(
    consolidadoTruckId,
    orderBy?,
    limit?,
    offset?,
    filter?
  ) {
    const params = {
      sort: orderBy,
      size: limit,
      page: offset,
    };

    const response = await authAxios2.get(
      `/portal/consolidado-remito/truck/${consolidadoTruckId}`,
      { params }
    );

    return response.data;
  }

  static async deleteRemito(consolidadoRemitoId) {
    const response = await authAxios2.delete(
      `/portal/consolidado-remito/${consolidadoRemitoId}`
    );
    return response.data;
  }

  static async editTruck(data) {
    const response = await authAxios2.put(
      `/portal/consolidado-truck/${data?.consolidadoTruckId}`,
      data
    );
    return response.data;
  }

  static async getTruckById(data) {
    const response = await authAxios2.get(`/portal/consolidado-truck/${data}`);
    return response.data;
  }

  static async deleteConsolidatedTruck(data) {
    const response = await authAxios2.delete(
      `/portal/consolidado-truck/${data}`
    );
    return response.data;
  }

  //endpoint ok
  static async getCommodity() {
    const response = await authAxios2.get(`/portal/commodity-type`);
    return response.data;
  }

  static async getPackaging() {
    const response = await authAxios2.get(`/portal/packaging`);
    return response.data;
  }

  //endpoint ok
  static async getTimeFring() {
    const response = await authAxios2.get(
      `portal/consolidado-header/franjas-horarias`
    );

    return response.data;
  }

  static async getTransport() {
    const response = await authAxios2.get(`/portal/transporte`);
    return response.data;
  }

  static async getPreStatus() {
    const response = await authAxios2.get(
      `/portal/consolidado-header/prestatus`
    );
    return response.data;
  }
  static async getStatus() {
    const response = await authAxios2.get(`/portal/consolidado-header/status`);
    return response.data;
  }
  //turnos de consolidado
  static async editHeaderConsolidatedTurn(id, data) {
    const response = await authAxios2.put(
      `/portal/consolidado-header/${id}`,
      data
    );
    return response.data;
  }

  static async createTurnConsolidated(data) {
    let obj = {
      turnId: data?.turnId,
      date: data?.date,
      consolidadoHeaderId: parseInt(data.consolidadoHeaderId),
      otroHorario: true,
      numberCamiones: data?.numberCamiones,
      numberContainer: data?.numberContainer,
      observations: data?.observations,
    };
    const response = await authAxios2.post(`/portal/consolidado-turn`, obj);
    return response.data;
  }

  static async getTurnConsolidatedById(
    consolidadoHeaderId,
    orderBy,
    limit?,
    offset?
  ) {
    const params = {
      sort: orderBy,
      size: limit,
      page: offset,
    };
    const response = await authAxios2.get(
      `/portal/consolidado-turn/header/${consolidadoHeaderId}`,
      { params }
    ); //consulta de turnos de consolidadoheader dado un id de consolidadoheader
    return response.data;
  }
  static async getTurnConsolidated(orderBy?, limit?, offset?, filter?) {
    const obj = {
      sort: orderBy,
      size: limit,
      page: offset,
    };

    const params = Object.assign({}, obj, filter);

    const response = await authAxios2.get(`/portal/consolidado-turn`, {
      params,
    });
    return response.data;
  }

  //logistica
  static async editConsolidatedTurn(id, data) {
    const response = await authAxios2.put(
      `/portal/consolidado-turn/${id}`,
      data
    );
    return response.data;
  }

  static async closeTurn(consolidadoTurnId) {
    const response = await authAxios2.put(
      `/portal/consolidado-turn/${consolidadoTurnId}/status/cerrado`
    );

    return response.data;
  }

  static async serviceEdition(id, data) {
    const response = await authAxios2.put(
      `/portal/consolidado-header/${id}/sva`,
      data
    );
    return response.data;
  }

  static async createAttachment(id, data) {
    const response = await authAxios2.put(
      `/portal/consolidado-header/${id}/attachment`,
      data
    );
    return response.data;
  }

  static async getAttachment(data) {
    const response = await authAxios2.get(
      `/portal/consolidado-header/${data}/attachment`
    );
    return response.data;
  }

  static async getEmbarques(orderBy, limit?, offset?, filter?, data?) {
    const obj = {
      sort: orderBy,
      size: limit,
      page: offset,
    };

    const params = data
      ? {
          consolidadoHeaderId: data,
        }
      : Object.assign({}, obj, filter);
    const response = await authAxios2.get(`/portal/permisoEmbarque`, {
      params,
    });
    return response.data;
  }
  static async createEmbarque(data, files) {
    let formData = new FormData();
    const permisoEmbarqueDto = JSON.stringify(data);
    const blob = new Blob([permisoEmbarqueDto], {
      type: "application/json",
    });

    formData.append("permisoEmbarqueDto", blob);
    formData.append("file", files[0]);

    const response = await authAxios3.post(`/portal/permisoEmbarque`, formData);
    if (response.data.code === "MSCO-EXPRT-LCL2-009-403")
      return Message.error(`${response.data.message}`);
    return response.data;
  }
  static async editEmbarque(data, files) {
    const response = await authAxios2.put(`/portal/permisoEmbarque`, data);
    if (response.data.code === "MSCO-EXPRT-LCL2-009-403")
      return Message.error(`${response.data.message}`);
    return response.data;
  }

  static async deleteEmbarque(data) {
    const response = await authAxios2.delete(`/portal/permisoEmbarque/${data}`);
    return response.data;
  }

  static async deleteAttachment() {
    const response = await authAxios2.put(
      `/portal/consolidado-header/idHeader/attachment/IdddeAtta`
    );
    return response.data;
  }

  static async getServicesForOperationId(data) {
    const response = await authAxios2.get(`/portal/operation/${data}/services`);
    return response.data;
  }

  static async doAllService() {
    const response = await authAxios2.get(`/portal/services`);
    return response.data;
  }

  //Estados para filtros
  static async getStatusConsolidated(data) {
    const response = await authAxios2.get(
      `/portal/consolidado-turn/status?showAll=${data}`
    );
    return response.data;
  }
  //edicion de un turno consolidado
  static async editTurnConsolidated(data?) {
    let body = {
      turnId: data?.turnId,
      date: data?.date,
      numberCamiones: data?.numberCamiones,
      numberContainer: data?.numberContainer,
      observations: data?.observations,
    };

    const { id } = data;

    const response = await authAxios2.put(
      `/portal/consolidado-turn/${id}/turn`,
      body
    );
    return response.data;
  }

  //consolidado otro horario

  static async scheduledOther(data) {
    let body = {
      date: data?.date,
      consolidadoHeaderId: parseInt(data?.idHeader),
      numberCamiones: data?.numberCamiones,
      numberContainers: data?.numberContainers,
      observations: data?.observations,
    };
    const response = await authAxios2.post(
      `/portal/consolidado-turn/otro-horario`,
      body
    );

    return response.data;
  }

  //edicion consolidado otro horario
  static async editScheduledOther(data) {
    let body = {
      date: data?.date,
      numberCamiones: data?.numberCamiones,
      numberContainer: data?.numberContainer,
      observations: data?.observations,
    };
    const response = await authAxios2.put(
      `/portal/consolidado-turn/${data?.id}/otro-horario`,
      body
    );
    return response.data;
  }
}
