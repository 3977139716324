import { createSelector } from "reselect";

const selectRaw = (state) => state.exportR.bookings;

const selectLoading = createSelector([selectRaw], (raw) => raw.loading);

const selectRows = createSelector([selectRaw], (raw) => raw.rows);

const selectRowsCsv = createSelector([selectRaw], ({ rows }) => {
  const columnsHeader = [
    { id: "booking_number", displayName: "Booking" },
    { id: "booking_line", displayName: "Línea" },
    { id: "vessel_id", displayName: "Buque" },
    { id: "full_destination", displayName: "Destino" },
    { id: "shipper_date_arrival", displayName: "ETA" },
    { id: "vessel_time_cargo_cutoff", displayName: "Cut Off" },
    { id: "quantity", displayName: "Contenedores Totales" },
    { id: "tally_out", displayName: "Vacíos retirados" },
    { id: "tally_in", displayName: "Fulles ingresados" },
    { id: "desc_vessel_phase", displayName: "Estado del Buque" },
  ];

  return [columnsHeader];
});

const selectBookingDetails = createSelector(
  [selectRaw],
  (raw) => raw.bookingDetails,
);
const selectLoadingDetails = createSelector(
  [selectRaw],
  (raw) => raw.loadingDetails,
);

const selectCount = createSelector([selectRaw], (raw) => raw.count);

const selectHasRows = createSelector([selectRaw], (raw) => raw.count > 0);

const selectSorter = createSelector([selectRaw], (raw) => raw.sorter || {});

const selectOrderBy = createSelector([selectRaw], (raw) => {
  const sorter = raw.sorter;

  if (!sorter) {
    return null;
  }

  if (!sorter.field) {
    return null;
  }

  let direction = sorter.order === "desc" ? "DESC" : "ASC";

  return `${sorter.field},${direction}`;
});

const selectFilter = createSelector([selectRaw], (raw) => {
  return raw.filter;
});
const selectCuitSelected = createSelector([selectRaw], (raw) => {
  return raw.cuitSelected;
});
const selectLimit = createSelector([selectRaw], (raw) => {
  const pagination = raw.pagination;
  return pagination.pageSize;
});

const selectOffset = createSelector([selectRaw], (raw) => {
  const pagination = raw.pagination;

  if (!pagination || !pagination.pageSize) {
    return 0;
  }

  const current = pagination.current || 1;

  return current - 1;
});

const selectPagination = createSelector(
  [selectRaw, selectCount],
  (raw, count) => {
    return {
      ...raw.pagination,
      total: count,
    };
  },
);

const exportBookingsSelectors = {
  selectLoading,
  selectRows,
  selectRowsCsv,
  selectBookingDetails,
  selectLoadingDetails,
  selectCount,
  selectHasRows,
  selectSorter,
  selectOrderBy,
  selectLimit,
  selectFilter,
  selectOffset,
  selectPagination,
  selectCuitSelected,
};

export default exportBookingsSelectors;
