import actions from './stakeholderBookingListActions';

const INITIAL_PAGE_SIZE = 10;


const status = [
	{
		value: "ASSIGNED",
		label: "Asignado"
	},
	{
		value: "NOT_ASSIGNED",
		label: "Sin Asignar"
	},
];

const bookingData = {
	"createdAt": null,
	"modifiedAt": null,
	"createdBy": null,
	"modifiedBy": null,
	"enabled": null,
	"id": null,
	"value": "",
	"type": "BOOKING",
	"status": "NOT_ASSIGNED",
	"clientCompany": null,
	"clientCompanyId": null,
	"stakeholderId": null,
	"stakeholder": null,
	"vesselId": "NORDA937N",
	"port": "GBLON",
	"line": "HSA",
	"containerQuantity": 1,
	"hideEmpty": null,
	"hideFull": null,
	"hideConsolidado": null,
	"cuitPropietario": "33669088499",
	"sinDespachante": null,
	"cutoff": "2019-09-11 18:00:00.0",
	"vessel_phase": null,
	"shipper_date_arrival": null,
	"shipper_date_departure": null,
	"buque": null,
	"puerto_descarga": null,
	"puerto_final": null,
	"tally_in": null,
	"tally_out": null,
	"hazards": null,
	"shipper_id": null,
	"shipper_name": null
}

const initialState = {
	rows: [],
	count: 0,
	loading: false,
	filter: {},
	pagination: {
		current: 1,
		pageSize: INITIAL_PAGE_SIZE,
	},
	status,
	message: '',
	row: undefined

};

const stakeholderBooking = (state = initialState, { type, payload }) => {

	if (type === actions.ASIGNACION_STARTED) {
		return {
			...state,
			loading: true,
		};
	}

	if (type === actions.ASIGNACION_SUCCESS) {
		return {
			...state,
			message: payload,
			row: undefined,
			loading: false,
		};
	}

	if (type === actions.ASIGNACION_ERROR) {
		return {
			...state,
			row: undefined,
			loading: false,
		};
	}

	if (type === actions.GET_BOOKING_STARTED) {
		return {
			...state,
			loading: true,
		};
	}

	if (type === actions.GET_BOOKING_SUCCESS) {
		return {
			...state,
			row: payload.data,
			loading: false,
		};
	}

	if (type === actions.GET_BOOKING_CANCEL) {
		return {
			...state,
			row: undefined,
			loading: false,
		};
	}

	if (type === actions.GET_BOOKING_ERROR) {
		return {
			...state,
			loading: false,
		};
	}

	if (type === actions.FETCH_STARTED) {
		return {
			...state,
			loading: true,
			pagination: state.pagination,
		};
	}

	if (type === actions.CHANGE_RESET) {
		return {
			...state,
			pagination: {
				current: 1,
				pageSize: INITIAL_PAGE_SIZE,
			},
		};
	}

	if (type === actions.FETCH_SUCCESS) {
		return {
			...state,
			loading: false,
			rows: payload.list,
			count: payload.total,
		};
	}

	if (type === actions.FETCH_ERROR) {
		return {
			...state,
			loading: false,
			rows: [],
			count: 0,
		};
	}

	if (type === actions.PAGINATION_CHANGED) {
		return {
			...state,
			pagination: payload || {
				current: 1,
				pageSize: INITIAL_PAGE_SIZE,
			},
		};
	}

	if (type === actions.SORTER_CHANGED) {
		return {
			...state,
			sorter: payload || {},
		};
	}

	if (type === actions.CHANGE_FILTER) {
		return {
			...state,
			filter: payload,
		};
	}




	return state;
};

export default stakeholderBooking;
