import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Menu,
  MenuItem,
  makeStyles,
  Avatar,
  Typography,
} from "@material-ui/core";
import authSelectors from "src/modules/auth/authSelectors";
import { getHistory } from "src/modules/store";
import { i18n } from "src/i18n";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { AccountCircleOutlined } from "@material-ui/icons";
import layoutActions from "src/modules/layout/layoutActions";
import { useMsal } from "@azure/msal-react";

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.primary.main,
  },
  buttonInner: {
    display: "flex",
    alignItems: "center",
  },
  text: {
    margin: theme.spacing(0, 0.5, 0, 1),
    display: "none",
    flexDirection: "column",
    alignItems: "flex-start",

    color: theme.palette.text.primary,
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  optionText: {
    margin: theme.spacing(0, 0.5, 0, 1),
    color: theme.palette.primary.main,
  },
  icon: {
    color: theme.palette.primary.main,
  },
}));

function UserMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { instance } = useMsal();

  const dispatch = useDispatch();
  const classes = useStyles();
  const userAvatar = useSelector(authSelectors.selectCurrentUserAvatar);
  const user = useSelector(authSelectors.selectCurrentUser);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const doSignout = async () => {
    const currentAccount = instance?.getAccountByHomeId(
      instance.getAllAccounts()[0]?.homeAccountId
    );
    await instance.logout({ account: currentAccount });
  };

  const doNavigateToProfile = () => {
    getHistory().push("/profile");
  };

  return (
    <>
      {/* <img
        src="/images/logo-blanco-murchison.svg"
        alt="Logo Terminal Zárate"
        style={{
          maxWidth: "200px",
          maxHeight: "60px",
          marginRight: "5vw",
        }}
      /> */}
      <Button className={classes.button} onClick={handleClick}>
        <div className={classes.buttonInner}>
          {/* {userAvatar && <Avatar src={userAvatar} alt='avatar' />}
					{!userAvatar && <AccountCircleOutlined color='primary'/>} */}
          <Avatar style={{ color: "white", backgroundColor: "black" }}>
            {user?.firstName.substr(0, 1)}{user?.lastName.substr(0, 1)}
          </Avatar>
          <div className={classes.text}>
            <Typography>
              {user?.firstName} {user?.lastName}
            </Typography>
          </div>
        </div>
      </Button>
      <Menu
        style={{ marginTop: "3.8rem", marginLeft: "1rem" }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            doNavigateToProfile();
            dispatch(layoutActions.doShowMenu());
          }}
        >
          <PersonOutlineIcon className={classes.icon} />
          <span className={classes.optionText}>
            {i18n("auth.profile.title")}
          </span>
        </MenuItem>
        <MenuItem onClick={doSignout}>
          <ExitToAppIcon className={classes.icon} />
          <span className={classes.optionText}>{i18n("auth.signout")}</span>
        </MenuItem>
      </Menu>
    </>
  );
}

export default UserMenu;
